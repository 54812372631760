export default [
  {
    id: '1579',
    type: 'vendibleTag',
    attributes: {
      id: 1579,
      name: 'キャストドリンク',
      position: 0,
    },
  },
  {
    id: '1580',
    type: 'vendibleTag',
    attributes: {
      id: 1580,
      name: 'ショット',
      position: 0,
    },
  },
  {
    id: '1581',
    type: 'vendibleTag',
    attributes: {
      id: 1581,
      name: 'ボトル',
      position: 0,
    },
  },
  {
    id: '1582',
    type: 'vendibleTag',
    attributes: {
      id: 1582,
      name: 'シャンパン',
      position: 0,
    },
  },
  {
    id: '1583',
    type: 'vendibleTag',
    attributes: {
      id: 1583,
      name: 'チャージ',
      position: 0,
    },
  },
  {
    id: '1576',
    type: 'vendibleTag',
    attributes: {
      id: 1576,
      name: '指名',
      position: 0,
    },
  },
  {
    id: '1577',
    type: 'vendibleTag',
    attributes: {
      id: 1577,
      name: 'セット',
      position: 0,
    },
  },
  {
    id: '1578',
    type: 'vendibleTag',
    attributes: {
      id: 1578,
      name: '延長',
      position: 0,
    },
  },
]
