export default [
  {
    id: '496',
    type: 'vendingContributionTrigger',
    attributes: {
      triggerableType: 'Referral',
      triggerableId: 418,
    },
    meta: {
      name: '案内所',
    },
  },
  {
    id: '497',
    type: 'vendingContributionTrigger',
    attributes: {
      triggerableType: 'Referral',
      triggerableId: 419,
    },
    meta: {
      name: '外販',
    },
  },
  {
    id: '498',
    type: 'vendingContributionTrigger',
    attributes: {
      triggerableType: 'Nomination',
      triggerableId: 434,
    },
    meta: {
      name: '本指名',
    },
  },
]
