<template>
  <v-navigation-drawer
    ref="drawer"
    :value="true"
    app
    right
    temporary
    stateless
    class="table-show-drawer order-tutorial-page"
    width="100%"
  >
    <v-app-bar
      v-if="true"
      outlined
      fixed
      flat
      style="z-index: 10"
    >
      <v-app-bar-nav-icon>
        <v-btn
          fab
          small
          :ripple="false"
          @click="$router.push({ name: 'waiter-settings-club', params: { 'club_slug': currentClub.slug } })"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-app-bar-nav-icon>

      <v-app-bar-title>
        <template #default>
          <table-name
            table-number=""
            table-prefix=""
            table-suffix="注文操作ガイド"
          />
        </template>
      </v-app-bar-title>

      <v-spacer></v-spacer>
      <v-btn
        color="error"
        :ripple="false"
        small
      >
        チュートリアルガイド中
      </v-btn>
    </v-app-bar>

    <v-card
      tile
      elevation="0"
      height="100%"
    >
      <v-tabs-items
        :value="0"
        touchless
        class="h-full"
      >
        <v-tab-item
          reverse-transition
          class="h-full"
        >
          <div :style="{ paddingRight: $vuetify.breakpoint.xsOnly ? 0 : '400px' }">
            <Menu
              :vendibles="vendibles"
              :vendible-tags="vendibleTags"
              style="padding-top: 62px"
              course-collapsed
              nomination-collapsed
              class="px-4 order-menu-list"
              @click:row="addVendingHistoryTemp({
                vendibleId: $event.id,
                vendibleType: $event.attributes.vendibleType,
                sellingPrice: $event.attributes.sellingPrice,
              })"
            />

            <v-navigation-drawer
              app
              right
              stateless
              permanent
              hide-overlay
              temporary
              class="shopping-cart"
              width="400"
            >
              <v-card elevation="0">
                <v-card-text
                  class="pb-16 mb-8"
                  style="padding-top: 62px"
                >
                  <v-card elevation="0">
                    <transition-group
                      name="fadeUp"
                      class="d-flex order-cards"
                      :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-column-reverse'"
                    >
                      <div
                        v-for="(vendingHistoryTemp, vendingHistoryTempIdx) in vendingHistoriesTemp"
                        :key="`vendingHistoriesTemp[${vendingHistoryTempIdx}]`"
                        style="animation-duration: 0.3s"
                      >
                        <!-- eslint-disable vue/v-on-event-hyphenation -->
                        <order-card
                          :id="`order-card-${vendingHistoryTempIdx}`"
                          :key="`order-card-${vendingHistoryTempIdx}/${vendingHistoriesTemp.length}`"
                          v-model="vendingHistoryTemp.value"
                          :vendibles="vendibles"
                          :users="users"
                          :size="vendingHistoryTemp.size"
                          class="mt-3 mb-8 order-card"
                          @update:vendibleId="vendingHistoryTemp.value.vendibleId = $event"
                          @update:vendibleType="vendingHistoryTemp.value.vendibleType = $event"
                          @update:isNonExistVendible="vendingHistoryTemp.value.isNonExistVendible = $event"
                          @update:vendibleName="vendingHistoryTemp.value.vendibleName = $event"
                          @update:sellingPrice="vendingHistoryTemp.value.sellingPrice = $event"
                          @update:taxCharge="vendingHistoryTemp.value.taxCharge = $event"
                          @update:vendingContributionsAttributes="vendingHistoryTemp.value.vendingContributionsAttributes = $event"
                          @update:isArbitraryVendingContribution="vendingHistoryTemp.isArbitraryVendingContribution = $event"
                          @update:vendibleRecipientsAttributes="vendingHistoryTemp.value.vendibleRecipientsAttributes = $event"
                          @update:size="vendingHistoryTemp.size = $event"
                          @remove="removeVendingHistoryTempByIndex(vendingHistoryTempIdx)"
                        >
                          <template #outside>
                            <template v-if="isSplitEstimation">
                              <v-text-field
                                :value="`¥${tableTotal.toLocaleString()}`"
                                readonly
                                hide-details
                                disabled
                                class="right-input font-weight-bold px-2 pb-4"
                              >
                                <template #prepend-inner>
                                  <span class="text-sm">
                                    現在のお会計
                                  </span>
                                </template>
                              </v-text-field>

                              <total-display
                                :subtotal="vendingHistoryTemp.value.sellingPrice * vendingHistoryTemp.size"
                                :tax="vendingHistoryTemp.estimatedTaxPrice * vendingHistoryTemp.size"
                                :total="getTotal(vendingHistoryTempIdx)"
                                :paid-in-advance-amount="paidInAdvanceAmount"
                                :title="'概算'"
                                :total-msg="'注文後のお会計'"
                                class="mb-8 mt-4 px-2"
                              >
                                <template #below-title>
                                  <v-switch
                                    v-if="vendingHistoriesTemp.length > 1"
                                    v-model="isSplitEstimation"
                                    messages="商品別"
                                    dense
                                  />
                                </template>
                              </total-display>
                            </template>
                          </template>
                        </order-card>
                      </div>
                    </transition-group>
                  </v-card>

                  <v-card
                    elevation="0"
                    class="my-10 d-flex align-center justify-center"
                  >
                    <v-btn
                      fab
                      icon
                      large
                      color="primary"
                      :ripple="false"
                      @click="addVendingHistoryTemp({})"
                    >
                      <v-icon large>
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </v-card>

                  <template v-if="!isSplitEstimation">
                    <v-text-field
                      :value="`¥${tableTotal.toLocaleString()}`"
                      readonly
                      hide-details
                      disabled
                      class="right-input font-weight-bold px-2 pb-4"
                    >
                      <template #prepend-inner>
                        <span class="text-sm">
                          現在のお会計
                        </span>
                      </template>
                    </v-text-field>

                    <total-display
                      :subtotal="subtotal"
                      :tax="totalTax"
                      :total="estimatedTotal"
                      :paid-in-advance-amount="paidInAdvanceAmount"
                      :title="'概算'"
                      :total-msg="'注文後のお会計'"
                      class="mb-4 px-2"
                    >
                      <template #below-title>
                        <v-switch
                          v-if="vendingHistoriesTemp.length > 1"
                          v-model="isSplitEstimation"
                          messages="商品別"
                          dense
                        />
                      </template>
                    </total-display>
                  </template>

                  <div class="mt-10">
                    <div class="mt-1"></div>
                  </div>

                  <v-app-bar
                    bottom
                    outlined
                    fixed
                    height="76"
                    class="pa-0 order-app-bar"
                  >
                    <div class="d-flex flex-column align-center w-full h-full px-5">
                      <v-spacer />
                      <v-btn
                        color="primary"
                        block
                        :ripple="false"
                        :disabled="!isValidContents"
                        class="order-btn"
                        @click="() => {}"
                      >
                        {{ `注文（¥${(subtotal + totalTax).toLocaleString()}）` }}
                      </v-btn>
                      <v-spacer />
                    </div>
                  </v-app-bar>
                </v-card-text>
              </v-card>
            </v-navigation-drawer>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import {
  ref,
  computed,
  watch,
  provide,
} from '@vue/composition-api'
import {
  cloneDeep,
  sumBy,
  flatMap,
  each,
  isEqual,
  map,
  isEmpty,
  includes,
  find,
  update,
} from 'lodash'
import {
  mdiPlus,
  mdiCalculatorVariant,
  mdiCalculatorVariantOutline,
  mdiArrowSplitHorizontal,
  mdiClose,
} from '@mdi/js'
import useVendingHistory from '@/views/composable/useVendingHistory'
import OrderCard from '@/views/components/table/OrderCard/OrderCard.vue'
import Menu from '@/views/components/vendible/Menu.vue'
import TotalDisplay from '@/views/components/util/TotalDisplay.vue'
import TableName from '@/views/components/table/TableName.vue'
import vendibles from '@/data/tutorial/vendibles'
import vendibleTags from '@/data/tutorial/vendible-tags'
import users from '@/data/tutorial/users'
import vendingContributionTriggers from '@/data/tutorial/vending-contribution-triggers'

export default {
  components: {
    OrderCard,
    Menu,
    TotalDisplay,
    TableName,
  },
  setup() {
    provide('vendingContributionTriggers', ref(vendingContributionTriggers))

    const tableTotal = ref(0)
    const estimatedTotal = ref(0)
    const estimatedTotals = ref([])
    const isSplitEstimation = ref(false)

    const {
      vendingHistoriesTemp,
      addVendingHistoryTemp,
      removeVendingHistoryTempByIndex,
      isValidToSubmit,
      vendingHistoriesToSubmit,
      setVendingContributionsAttributes,
    } = useVendingHistory()

    const isValidContents = computed(() => {
      return vendingHistoriesToSubmit.value.length > 0 && isValidToSubmit.value
    })

    // NOTE: TAX計算をさせるwatch対象
    const taxEstimationTargets = computed(() => {
      return flatMap(vendingHistoriesTemp.value, o => {
        const { sellingPrice, taxCharge } = o.value

        return [sellingPrice, taxCharge]
      })
    })

    // NOTE: 大計計算をさせるwatch対象
    const totalEstimationTargets = computed(() => {
      // NOTE TAXの計算結果たち（注文内容の変化に応じてtax計算されるので）
      const estimatedTaxPrices = map(vendingHistoriesTemp.value, o => o.estimatedTaxPrice)

      // NOTE + 注文する内容の個数
      return [...estimatedTaxPrices, vendingHistoriesToSubmit.value.length]
    })

    const subtotal = computed(() => {
      return sumBy(vendingHistoriesToSubmit.value, vendingHistoryTemp => Number(vendingHistoryTemp.sellingPrice))
    })

    const totalTax = computed(() => {
      return sumBy(vendingHistoriesTemp.value, vendingHistoryTemp => {
        return vendingHistoryTemp.size * vendingHistoryTemp.estimatedTaxPrice
      })
    })

    const calcTotal = async (orderPrice = null) => {
      return orderPrice || 0
    }

    const calcTax = async price => {
      return Math.round((price * 1.2) / 10) * 10
    }

    const getTotal = index => {
      const estimatedTotalObj = find(estimatedTotals.value, o => o.vendingHistoryTempIdx === index)

      return estimatedTotalObj?.amount
    }

    // NOTE: 概算を出すためにそれぞれのvendingHistoryの税サを計算
    watch(() => cloneDeep(taxEstimationTargets.value), (newVal, prevVal) => {
      if (isEqual(newVal, prevVal)) return

      each(vendingHistoriesTemp.value, vendingHistoryTemp => {
        if (vendingHistoryTemp.value.taxCharge === 'normal') {
          calcTax(vendingHistoryTemp.value.sellingPrice).then(taxPrice => {
            update(vendingHistoryTemp, 'estimatedTaxPrice', () => taxPrice)
          })
        } else {
          update(vendingHistoryTemp, 'estimatedTaxPrice', () => 0)
        }
      })
    }, { immediate: true })

    // NOTE: 概算を出すためにそれぞれのvendingHistoryの大計を計算
    watch(() => cloneDeep(totalEstimationTargets.value), (newVal, prevVal) => {
      if (isEmpty(newVal)) return
      if (includes(newVal, null)) return
      if (isEqual(newVal, [0])) return
      if (isEqual(newVal, prevVal)) return

      estimatedTotals.value = []
      each(vendingHistoriesTemp.value, (vendingHistoryTemp, index) => {
        const sellingPrice = Number(vendingHistoryTemp.value.sellingPrice)
        const estimatedTaxPrice = Number(vendingHistoryTemp.estimatedTaxPrice)
        const rawOrderTotal = (sellingPrice + estimatedTaxPrice) * vendingHistoryTemp.size

        calcTotal(rawOrderTotal).then(res => {
          estimatedTotals.value.push({
            vendingHistoryTempIdx: index,
            amount: res,
          })
        })
      })
    }, { immediate: true })

    // NOTE: 概算を出すためにvendingHistory全体の大計を計算
    watch(() => cloneDeep(estimatedTotals.value), (newVal, prevVal) => {
      if (isEqual(newVal, prevVal)) return

      const rawOrderTotal = subtotal.value + totalTax.value

      calcTotal(rawOrderTotal).then(res => {
        estimatedTotal.value = res
      })
    }, { immediate: true })

    // NOTE: 販売貢献人をvendingHistoryTempに自動セットするwatch
    watch(() => cloneDeep(vendingHistoriesTemp.value), (_newVal, _prevVal) => {
      setVendingContributionsAttributes({
        targetVendingHistoriesTemp: vendingHistoriesTemp.value,
      })
    })

    return {
      // data
      tableTotal,
      vendingHistoriesTemp,
      estimatedTotal,
      estimatedTotals,
      isSplitEstimation,

      // computed
      isValidContents,
      subtotal,
      totalTax,
      taxEstimationTargets,
      totalEstimationTargets,

      // methods
      addVendingHistoryTemp,
      removeVendingHistoryTempByIndex,
      getTotal,
      icons: {
        mdiPlus,
        mdiCalculatorVariant,
        mdiCalculatorVariantOutline,
        mdiArrowSplitHorizontal,
        mdiClose,
      },

      paidInAdvanceAmount: 0,
      vendibles,
      vendibleTags,
      users,
      vendingContributionTriggers,
      close: () => {},
    }
  },
}
</script>
