export default [
  {
    id: '7693',
    type: 'user',
    attributes: {
      name: 'A子',
      role: 'cast',
      id: 7693,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7694',
    type: 'user',
    attributes: {
      name: 'B美',
      role: 'cast',
      id: 7694,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7695',
    type: 'user',
    attributes: {
      name: 'C恵',
      role: 'cast',
      id: 7695,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7696',
    type: 'user',
    attributes: {
      name: 'D奈',
      role: 'cast',
      id: 7696,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7697',
    type: 'user',
    attributes: {
      name: 'E樹',
      role: 'cast',
      id: 7697,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7698',
    type: 'user',
    attributes: {
      name: 'F梨',
      role: 'cast',
      id: 7698,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7699',
    type: 'user',
    attributes: {
      name: 'G絵',
      role: 'cast',
      id: 7699,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7700',
    type: 'user',
    attributes: {
      name: 'H香',
      role: 'cast',
      id: 7700,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7701',
    type: 'user',
    attributes: {
      name: 'I花',
      role: 'cast',
      id: 7701,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7702',
    type: 'user',
    attributes: {
      name: 'J紀',
      role: 'cast',
      id: 7702,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7703',
    type: 'user',
    attributes: {
      name: 'K和',
      role: 'cast',
      id: 7703,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7704',
    type: 'user',
    attributes: {
      name: 'L奈',
      role: 'cast',
      id: 7704,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7705',
    type: 'user',
    attributes: {
      name: 'M美',
      role: 'cast',
      id: 7705,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7706',
    type: 'user',
    attributes: {
      name: 'N恵',
      role: 'cast',
      id: 7706,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7707',
    type: 'user',
    attributes: {
      name: 'O奈',
      role: 'cast',
      id: 7707,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7708',
    type: 'user',
    attributes: {
      name: 'P樹',
      role: 'cast',
      id: 7708,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7709',
    type: 'user',
    attributes: {
      name: 'Q梨',
      role: 'cast',
      id: 7709,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7710',
    type: 'user',
    attributes: {
      name: 'R絵',
      role: 'cast',
      id: 7710,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7711',
    type: 'user',
    attributes: {
      name: 'S香',
      role: 'cast',
      id: 7711,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7712',
    type: 'user',
    attributes: {
      name: 'T花',
      role: 'cast',
      id: 7712,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7713',
    type: 'user',
    attributes: {
      name: 'U紀',
      role: 'cast',
      id: 7713,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7714',
    type: 'user',
    attributes: {
      name: 'V和',
      role: 'cast',
      id: 7714,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7715',
    type: 'user',
    attributes: {
      name: 'W奈',
      role: 'cast',
      id: 7715,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7716',
    type: 'user',
    attributes: {
      name: 'X美',
      role: 'cast',
      id: 7716,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7717',
    type: 'user',
    attributes: {
      name: 'Y恵',
      role: 'cast',
      id: 7717,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7718',
    type: 'user',
    attributes: {
      name: 'Z樹',
      role: 'cast',
      id: 7718,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7692',
    type: 'user',
    attributes: {
      name: 'スタッフ',
      role: 'waiter',
      id: 7692,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7719',
    type: 'user',
    attributes: {
      name: 'ステーション1',
      role: 'alliance',
      id: 7719,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7720',
    type: 'user',
    attributes: {
      name: 'ステーション2',
      role: 'alliance',
      id: 7720,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7721',
    type: 'user',
    attributes: {
      name: '一郎',
      role: 'alliance',
      id: 7721,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7723',
    type: 'user',
    attributes: {
      name: '三郎',
      role: 'alliance',
      id: 7723,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
  {
    id: '7722',
    type: 'user',
    attributes: {
      name: '次郎',
      role: 'alliance',
      id: 7722,
      subscriptionPayload: '{}',
      profileImage: null,
    },
  },
]
