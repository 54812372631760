export default [
  {
    id: '7523',
    type: 'item',
    attributes: {
      id: 7523,
      name: 'Sドリンク',
      description: null,
      sellingPrice: 1000,
      costPrice: null,
      position: 1,
      vendibleType: 'Item',
      itemKey: 'Item_7523',
      vendibleTaggings: {
        data: [
          {
            id: '11313',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Item',
              vendibleId: 7523,
              vendibleTagId: 1579,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1579',
            type: 'vendibleTag',
            attributes: {
              id: 1579,
              name: 'キャストドリンク',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
    },
  },
  {
    id: '7524',
    type: 'item',
    attributes: {
      id: 7524,
      name: 'Mドリンク',
      description: null,
      sellingPrice: 2000,
      costPrice: null,
      position: 2,
      vendibleType: 'Item',
      itemKey: 'Item_7524',
      vendibleTaggings: {
        data: [
          {
            id: '11314',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Item',
              vendibleId: 7524,
              vendibleTagId: 1579,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1579',
            type: 'vendibleTag',
            attributes: {
              id: 1579,
              name: 'キャストドリンク',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
    },
  },
  {
    id: '7525',
    type: 'item',
    attributes: {
      id: 7525,
      name: 'Lドリンク',
      description: null,
      sellingPrice: 3000,
      costPrice: null,
      position: 3,
      vendibleType: 'Item',
      itemKey: 'Item_7525',
      vendibleTaggings: {
        data: [
          {
            id: '11315',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Item',
              vendibleId: 7525,
              vendibleTagId: 1579,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1579',
            type: 'vendibleTag',
            attributes: {
              id: 1579,
              name: 'キャストドリンク',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
    },
  },
  {
    id: '7526',
    type: 'item',
    attributes: {
      id: 7526,
      name: 'テキーラ',
      description: null,
      sellingPrice: 2000,
      costPrice: null,
      position: 5,
      vendibleType: 'Item',
      itemKey: 'Item_7526',
      vendibleTaggings: {
        data: [
          {
            id: '11316',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Item',
              vendibleId: 7526,
              vendibleTagId: 1580,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1580',
            type: 'vendibleTag',
            attributes: {
              id: 1580,
              name: 'ショット',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
    },
  },
  {
    id: '7527',
    type: 'item',
    attributes: {
      id: 7527,
      name: 'コカボム',
      description: null,
      sellingPrice: 3000,
      costPrice: null,
      position: 6,
      vendibleType: 'Item',
      itemKey: 'Item_7527',
      vendibleTaggings: {
        data: [
          {
            id: '11317',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Item',
              vendibleId: 7527,
              vendibleTagId: 1580,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1580',
            type: 'vendibleTag',
            attributes: {
              id: 1580,
              name: 'ショット',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
    },
  },
  {
    id: '7528',
    type: 'item',
    attributes: {
      id: 7528,
      name: 'アルマンド',
      description: null,
      sellingPrice: 150000,
      costPrice: null,
      position: 7,
      vendibleType: 'Item',
      itemKey: 'Item_7528',
      vendibleTaggings: {
        data: [
          {
            id: '11318',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Item',
              vendibleId: 7528,
              vendibleTagId: 1581,
            },
          },
          {
            id: '11319',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Item',
              vendibleId: 7528,
              vendibleTagId: 1582,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1581',
            type: 'vendibleTag',
            attributes: {
              id: 1581,
              name: 'ボトル',
              position: 0,
            },
          },
          {
            id: '1582',
            type: 'vendibleTag',
            attributes: {
              id: 1582,
              name: 'シャンパン',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
    },
  },
  {
    id: '7529',
    type: 'item',
    attributes: {
      id: 7529,
      name: '同伴',
      description: null,
      sellingPrice: 5000,
      costPrice: null,
      position: 8,
      vendibleType: 'Item',
      itemKey: 'Item_7529',
      vendibleTaggings: {
        data: [
          {
            id: '11320',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Item',
              vendibleId: 7529,
              vendibleTagId: 1583,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1583',
            type: 'vendibleTag',
            attributes: {
              id: 1583,
              name: 'チャージ',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
    },
  },
  {
    id: '7530',
    type: 'item',
    attributes: {
      id: 7530,
      name: 'シングルチャージ',
      description: null,
      sellingPrice: 1500,
      costPrice: null,
      position: 9,
      vendibleType: 'Item',
      itemKey: 'Item_7530',
      vendibleTaggings: {
        data: [
          {
            id: '11321',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Item',
              vendibleId: 7530,
              vendibleTagId: 1583,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1583',
            type: 'vendibleTag',
            attributes: {
              id: 1583,
              name: 'チャージ',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
    },
  },
  {
    id: '434',
    type: 'nomination',
    attributes: {
      id: 434,
      name: '本指名',
      description: null,
      sellingPrice: 2000,
      costPrice: null,
      position: 1,
      vendibleType: 'Nomination',
      itemKey: 'Nomination_434',
      vendibleTaggings: {
        data: [
          {
            id: '11299',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Nomination',
              vendibleId: 434,
              vendibleTagId: 1576,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1576',
            type: 'vendibleTag',
            attributes: {
              id: 1576,
              name: '指名',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: {
          id: '498',
          type: 'vendingContributionTrigger',
          attributes: {
            triggerableType: 'Nomination',
            triggerableId: 434,
          },
          meta: {
            name: '本指名',
          },
        },
      },
      color: 'error',
    },
  },
  {
    id: '435',
    type: 'nomination',
    attributes: {
      id: 435,
      name: '場内指名',
      description: null,
      sellingPrice: 1000,
      costPrice: null,
      position: 2,
      vendibleType: 'Nomination',
      itemKey: 'Nomination_435',
      vendibleTaggings: {
        data: [
          {
            id: '11300',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Nomination',
              vendibleId: 435,
              vendibleTagId: 1576,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1576',
            type: 'vendibleTag',
            attributes: {
              id: 1576,
              name: '指名',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
      color: 'info',
    },
  },
  {
    id: '1182',
    type: 'course',
    attributes: {
      id: 1182,
      name: '19:00 - 21:00 セット',
      description: null,
      sellingPrice: 5000,
      costPrice: null,
      position: 1,
      vendibleType: 'Course',
      itemKey: 'Course_1182',
      vendibleTaggings: {
        data: [
          {
            id: '11301',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1182,
              vendibleTagId: 1577,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1577',
            type: 'vendibleTag',
            attributes: {
              id: 1577,
              name: 'セット',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
      effectiveDurationSeconds: 3600,
      passiveCourseExtensions: {
        data: [
          {
            id: '1545',
            type: 'courseExtension',
            attributes: {
              extendeeId: 1182,
              extenderId: 1186,
            },
          },
          {
            id: '1546',
            type: 'courseExtension',
            attributes: {
              extendeeId: 1182,
              extenderId: 1187,
            },
          },
        ],
      },
    },
  },
  {
    id: '1183',
    type: 'course',
    attributes: {
      id: 1183,
      name: '21:00 - セット',
      description: null,
      sellingPrice: 7000,
      costPrice: null,
      position: 2,
      vendibleType: 'Course',
      itemKey: 'Course_1183',
      vendibleTaggings: {
        data: [
          {
            id: '11302',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1183,
              vendibleTagId: 1577,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1577',
            type: 'vendibleTag',
            attributes: {
              id: 1577,
              name: 'セット',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
      effectiveDurationSeconds: 3600,
      passiveCourseExtensions: {
        data: [
          {
            id: '1547',
            type: 'courseExtension',
            attributes: {
              extendeeId: 1183,
              extenderId: 1186,
            },
          },
          {
            id: '1548',
            type: 'courseExtension',
            attributes: {
              extendeeId: 1183,
              extenderId: 1187,
            },
          },
        ],
      },
    },
  },
  {
    id: '1184',
    type: 'course',
    attributes: {
      id: 1184,
      name: '【VIP】19:00 - 21:00 セット',
      description: null,
      sellingPrice: 7000,
      costPrice: null,
      position: 3,
      vendibleType: 'Course',
      itemKey: 'Course_1184',
      vendibleTaggings: {
        data: [
          {
            id: '11303',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1184,
              vendibleTagId: 1577,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1577',
            type: 'vendibleTag',
            attributes: {
              id: 1577,
              name: 'セット',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
      effectiveDurationSeconds: 3600,
      passiveCourseExtensions: {
        data: [
          {
            id: '1549',
            type: 'courseExtension',
            attributes: {
              extendeeId: 1184,
              extenderId: 1188,
            },
          },
          {
            id: '1550',
            type: 'courseExtension',
            attributes: {
              extendeeId: 1184,
              extenderId: 1189,
            },
          },
        ],
      },
    },
  },
  {
    id: '1185',
    type: 'course',
    attributes: {
      id: 1185,
      name: '【VIP】21:00 - セット',
      description: null,
      sellingPrice: 10000,
      costPrice: null,
      position: 4,
      vendibleType: 'Course',
      itemKey: 'Course_1185',
      vendibleTaggings: {
        data: [
          {
            id: '11304',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1185,
              vendibleTagId: 1577,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1577',
            type: 'vendibleTag',
            attributes: {
              id: 1577,
              name: 'セット',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
      effectiveDurationSeconds: 3600,
      passiveCourseExtensions: {
        data: [
          {
            id: '1551',
            type: 'courseExtension',
            attributes: {
              extendeeId: 1185,
              extenderId: 1188,
            },
          },
          {
            id: '1552',
            type: 'courseExtension',
            attributes: {
              extendeeId: 1185,
              extenderId: 1189,
            },
          },
        ],
      },
    },
  },
  {
    id: '1186',
    type: 'course',
    attributes: {
      id: 1186,
      name: '延長',
      description: null,
      sellingPrice: 6000,
      costPrice: null,
      position: 5,
      vendibleType: 'Course',
      itemKey: 'Course_1186',
      vendibleTaggings: {
        data: [
          {
            id: '11305',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1186,
              vendibleTagId: 1577,
            },
          },
          {
            id: '11306',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1186,
              vendibleTagId: 1578,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1577',
            type: 'vendibleTag',
            attributes: {
              id: 1577,
              name: 'セット',
              position: 0,
            },
          },
          {
            id: '1578',
            type: 'vendibleTag',
            attributes: {
              id: 1578,
              name: '延長',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
      effectiveDurationSeconds: 3600,
      passiveCourseExtensions: {
        data: [],
      },
    },
  },
  {
    id: '1187',
    type: 'course',
    attributes: {
      id: 1187,
      name: 'ハーフ延長',
      description: null,
      sellingPrice: 3000,
      costPrice: null,
      position: 6,
      vendibleType: 'Course',
      itemKey: 'Course_1187',
      vendibleTaggings: {
        data: [
          {
            id: '11307',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1187,
              vendibleTagId: 1577,
            },
          },
          {
            id: '11308',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1187,
              vendibleTagId: 1578,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1577',
            type: 'vendibleTag',
            attributes: {
              id: 1577,
              name: 'セット',
              position: 0,
            },
          },
          {
            id: '1578',
            type: 'vendibleTag',
            attributes: {
              id: 1578,
              name: '延長',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
      effectiveDurationSeconds: 1800,
      passiveCourseExtensions: {
        data: [],
      },
    },
  },
  {
    id: '1188',
    type: 'course',
    attributes: {
      id: 1188,
      name: '【VIP】延長',
      description: null,
      sellingPrice: 9000,
      costPrice: null,
      position: 7,
      vendibleType: 'Course',
      itemKey: 'Course_1188',
      vendibleTaggings: {
        data: [
          {
            id: '11309',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1188,
              vendibleTagId: 1577,
            },
          },
          {
            id: '11310',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1188,
              vendibleTagId: 1578,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1577',
            type: 'vendibleTag',
            attributes: {
              id: 1577,
              name: 'セット',
              position: 0,
            },
          },
          {
            id: '1578',
            type: 'vendibleTag',
            attributes: {
              id: 1578,
              name: '延長',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
      effectiveDurationSeconds: 3600,
      passiveCourseExtensions: {
        data: [],
      },
    },
  },
  {
    id: '1189',
    type: 'course',
    attributes: {
      id: 1189,
      name: '【VIP】ハーフ延長',
      description: null,
      sellingPrice: 5000,
      costPrice: null,
      position: 8,
      vendibleType: 'Course',
      itemKey: 'Course_1189',
      vendibleTaggings: {
        data: [
          {
            id: '11311',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1189,
              vendibleTagId: 1577,
            },
          },
          {
            id: '11312',
            type: 'vendibleTagging',
            attributes: {
              vendibleType: 'Course',
              vendibleId: 1189,
              vendibleTagId: 1578,
            },
          },
        ],
      },
      tags: {
        data: [
          {
            id: '1577',
            type: 'vendibleTag',
            attributes: {
              id: 1577,
              name: 'セット',
              position: 0,
            },
          },
          {
            id: '1578',
            type: 'vendibleTag',
            attributes: {
              id: 1578,
              name: '延長',
              position: 0,
            },
          },
        ],
      },
      vendingContributionTrigger: {
        data: null,
      },
      effectiveDurationSeconds: 1800,
      passiveCourseExtensions: {
        data: [],
      },
    },
  },
]
